<template>
  <div
    class="tw-px-4 tw-pt-[40px] tw-pb-[40px] tablet:tw-px-[40px] desktop:tw-px-[80px] desktop:tw-pt-[60px] desktop:tw-pb-[60px]"
    style="box-shadow: 0px -8px 16px 0px #0000000D;"
  >
    <ap-snackbar ref="snackBar" />
    <!-- Request to remove logo 15/1/2024 -->
    <!-- <div>
      <img
        class="tw-w-[60vw] tw-h-auto tw-pb-[40px] tablet:tw-pb-[60px] desktop:tw-pb-[80px]"
        :src="$cdn(`/images/${getLanguage() === 'en' ? 'footer-singaporen.png' : 'footer-singaporen-cn.png'}`)"
        alt="Singaporen"
      >
    </div> -->

    <!-- Container -->
    <!-- <div class="tw-flex tw-flex-row"> -->
    <div
      class="tw-relative"
      :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
    >
      <div class="tw-grid tw-grid-cols-12">
        <!--Newsletter Module-->
        <div class="tw-max-w-[468px] tw-col-span-12 desktop:tw-col-span-6 tw-order-1 desktop:tw-order-1 tw-space-y-2 desktop:tw-pr-8">
          <p
            class="tw-text-sg-h4-mobile tablet:tw-text-sg-h4-tablet desktop:tw-text-sg-h4"
            :class="checkLang() == 'zh-hans'? '  tw-text-sg-sccc1 tw-text-sg-h4-mobile-dw-title-zh sgTablet:tw-text-sg-h4-tablet-dw-title-zh sgDesktop:tw-text-sg-h4-dw-title-zh tw-font-notosans': ' tw-text-sg-sccc1 tw-text-sg-h4-mobile-dw-title sgTablet:tw-text-sg-h4-tablet-dw-title sgDesktop:tw-sg-w-title tw-font-akkurat'"
          >
            {{ $t('subscribe_to_newsletter') }}
          </p>

          <div
            id="mc_embed_signup"
            class="tw-pt-3"
          >
            <form
              id="mc-embedded-subscribe-form"
              action="https://singaporeccc.us9.list-manage.com/subscribe/post?u=14c1885a30dbdb8bac594e408&amp;id=3cd66b30d4"
              method="post"
              name="mc-embedded-subscribe-form"
              class="validate"
              @submit="submitEmail"
            >
              <div id="mc_embed_signup_scroll">
                <div class="email-input-container">
                  <input
                    id="mce-EMAIL"
                    v-model="emailNewsletter"
                    type="email"
                    name="EMAIL"
                    class="required email tw-border-b tw-border-sg-sccc1-w60 tw-outline-none tw-ring-0 focus:!tw-ring-0 tw-border-0 focus:tw-border-b-2 focus:tw-border-sg-sccc1 tw-px-2 tw-py-1 tw-flex tw-flex-grow"
                    :placeholder="$t('email_address')"
                  >
                  <input
                    id="mc-embedded-subscribe"
                    type="hidden"
                    value="Submit"
                    name="subscribe"
                    class="button"
                  >
                  <input
                    name="form_nonce"
                    type="hidden"
                  >

                  <button>
                    <i class="email-icon tw-inline-flex">
                      <inline-svg
                        :src="$cdn('icons/arrow_forward.svg')"
                        class="tw-fill-ap-nobel tw-h-8 tw-w-8 tw-cursor-pointer"
                      />
                    </i>
                  </button>
                </div>
                <div class="checkbox-container tw-flex tw-items-center tw-pt-4">
                  <input
                    id="mce-group[41546]-41546-0"
                    v-model="acceptTerms"
                    type="checkbox"
                    value="1"
                    name="group[41546][1]"
                    :class="[
                      ' tw-peer tw-relative tw-appearance-none tw-shrink-0 tw-w-4 tw-h-4 tw-rounded-sm tw-border checked:tw-bg-sg-sccc1 checked:tw-border-sg-sccc1',
                      checkBoxStyle,
                    ]"
                    @change="checkCheckbox"
                  >
                  <svg
                    class="tw-absolute tw-w-4 tw-h-4 tw-pointer-events-none tw-stroke-white tw-fill-none peer-checked:!tw-fill-white"
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    width="14"
                    viewBox="0 0 448 512"
                  >
                    <!-- !Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                    />
                  </svg>
                  <label
                    for="termsCheckbox"
                    :class="[
                      'tw-ml-2 peer-checked:tw-text-sg-sccc1 tw-text-sg-label-mobile-4 tablet:tw-text-sg-label-4 desktop:tw-text-sg-label-4',
                      checkBoxLabelStyle,
                    ]"
                  >
                    {{ $t('acknowledgement_tnc') }}
                  </label>
                </div>

                <div
                  class="tw-inline-flex tw-text-sg-sccc1 tw-cursor-pointer tw-mt-5 tw-gap-x-1 tw-text-sg-label-mobile-4 tablet:tw-text-sg-label-4 desktop:tw-text-label"
                >
                  <a
                    class="hover:tw-underline"
                    :href="$langRedirect('/term-of-use')"
                  >{{ $t('terms_of_use') }}</a>
                  |
                  <a
                    class="hover:tw-underline"
                    :href="$langRedirect('/privacy-statement')"
                  >
                    {{ $t('privacy_statement') }}
                  </a>
                </div>
                <div
                  id="mce-responses"
                  class="clear"
                >
                  <div
                    id="mce-error-response"
                    class="response"
                    style="display: none"
                  />

                  <div
                    id="mce-success-response"
                    class="response"
                    style="display: none"
                  />
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div
                  style="position: absolute; left: -5000px"
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_14c1885a30dbdb8bac594e408_3cd66b30d4"
                    tabindex="-1"
                    value=""
                  >
                </div>
              </div>
            </form>
          </div>


        </div>

        <!-- Social Medias Icon-->
        <div class="tw-col-span-12 tw-flex tw-flex-row tw-justify-between tw-order-3 tablet:tw-order-3 tw-mt-14">
          <a
            href="https://www.facebook.com/singaporechineseculturalcentre/"
            target="_blank"
          >
            <inline-svg
              :src="$cdn('icons/facebook_icon.svg')"
              class="tw-w-11 tw-h-11 tw-p-1 hover:tw-fill-sg-sccc1 tw-fill-sg-sccc1-w60 tw-cursor-pointer"
              alt="Facebook Icon"
            />
          </a>
          <a
            href="https://www.instagram.com/singaporechineseculturalcentre/"
            target="_blank"
          >
            <inline-svg
              :src="$cdn('icons/instagram_icon.svg')"
              class="tw-w-11 tw-h-11 tw-p-1 hover:tw-fill-sg-sccc1 tw-fill-sg-sccc1-w60 tw-cursor-pointer"
              alt="Instagram Icon"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCAyGrbeMegLonj-e3dI_Rtg"
            target="_blank"
          >
            <inline-svg
              :src="$cdn('icons/youtube_icon.svg')"
              class="tw-w-11 tw-h-11 tw-p-1 hover:tw-fill-sg-sccc1 tw-fill-sg-sccc1-w60 tw-cursor-pointer"
              alt="Youtube Icon"
            />
          </a>
          <a
            href="https://singaporeccc.org.sg/wp-content/uploads/2020/11/SCCC-Wechat-QR-code.gif"
            target="_blank"
          >
            <inline-svg
              :src="$cdn('icons/wechat_icon.svg')"
              class="tw-w-11 tw-h-11 tw-p-1 hover:tw-fill-sg-sccc1 tw-fill-sg-sccc1-w60 tw-cursor-pointer"
              alt="Wechat Icon"
            />
          </a>
          <a
            href="https://www.tiktok.com/@singaporeccc?_d=secCgYIASAHKAESMgowS5pvPdwMYLaz74J1IabLrSV0Ov4b0URxVAetGxufMmIlDlBWDvU09d3KP%2Fy5lXgsGgA%3D&language=en&sec_uid=MS4wLjABAAAAx2iFq4pRkUMSrsWhepdJat3OEW18lpc8RV9nOiQET1yc2oxBCy4st_mu2-pZTR1t&share_app_name=tiktok&share_author_id=6895813322105472001&share_link_id=a0aa28a4-1fda-4807-bf78-8436cb49d577&timestamp=1612281833&utm_campaign=client_share&utm_medium=android&utm_source=copy&_r=1"
            target="_blank"
          >
            <inline-svg
              :src="$cdn('icons/tiktok_icon.svg')"
              class="tw-w-11 tw-h-11 tw-p-1 hover:tw-fill-sg-sccc1 tw-fill-sg-sccc1-w60 tw-cursor-pointer"
              alt="Tiktok Icon"
            />
          </a>
          <a
            href="https://www.xiaohongshu.com/user/profile/61e6c56f0000000010008831?xhsshare=CopyLink&appuid=5c6a4e6b000000001202d999&apptime=1703747125"
            target="_blank"
          >

            <inline-svg
              :src="$cdn('icons/xhs_icon.svg')"
              class="tw-w-11 tw-h-11 tw-p-1 hover:tw-fill-sg-sccc1 tw-fill-sg-sccc1-w60 tw-cursor-pointer"
              alt="XHS Icon"
            />
          </a>
          <a
            href="https://sg.linkedin.com/company/singapore-chinese-cultural-centre"
            target="_blank"
          >
            <inline-svg
              :src="$cdn('icons/linkedln_icon.svg')"
              class="tw-w-11 tw-h-11 tw-p-1 hover:tw-fill-sg-sccc1 tw-fill-sg-sccc1-w60 tw-cursor-pointer"
              alt="Linkedln Icon"
            />
          </a>
        </div>

        <!-- Menu links -->
        <div
          class="tw-pt-4 tablet:tw-pt-6 desktop:tw-pt-0 tw-col-span-12 desktop:tw-col-span-6 tw-order-2 tablet:tw-order-2 tw-flex tw-flex-col tablet:tw-flex-row tw-text-sg-sccc1-w60 tw-text-sg-sh-4 tw-cursor-pointer"
        >
          <!-- Singaporean Menu -->
          <div class="tw-flex tw-flex-col tw-w-1/2 tw-gap-y-4 tw-text-sg-body-1-mobile tablet:tw-text-sg-body-1-tablet dekstop:tw-text-sg-body-1">
            <div
              v-for="(item, index) in singaporenMenu"
              :key="item.label + index"
              class="tw-flex tw-flex-col tw-align-middle tw-group"
            >
              <a
                v-if="item.isAccordian === false"
                :href="$langRedirect(item.link, item.isExternal ? 'external': '')"
                :target="item.isExternal ? '_blank' : '_self'"
                class="hover:tw-text-sg-sccc1 tw-text-sg-sh2-mobile tablet:tw-text-sg-sh2-tablet desktop:tw-text-sg-sh2"
              >{{ item.label }}</a>

              <div
                v-else
                class="tw-inline-flex tw-flex-row hover:tw-text-sg-sccc1 tw-text-sg-sh2-mobile tablet:tw-text-sg-sh2-tablet desktop:tw-text-sg-sh2"
                :class="{
                  'tw-underline tw-text-sg-sccc1 ':
                    accordianActive.includes(item.label),
                }"
                @click="handleMenuClick(`${item.label}`)"
              >
                {{ item.label }}
                <div
                  v-if="item.isAccordian === true"
                  class="tw-ml-2 tw-align-middle tw-flex tw-items-center"
                >
                  <inline-svg
                    :src="$cdn('icons/arrow_right_purple.svg')"
                    alt="Arrow Right"
                    :class="{ '-tw-rotate-90 tw-transition-transform': accordianActive.includes(item.label) }"
                    class="tw-fill-sg-sccc1-w60 tw-h-4 tw-min-w-4 hover:tw-fill-sg-sccc1 group-hover:tw-fill-sg-sccc1"
                  />
                </div>
              </div>
              <Transition name="accordian-fade">
                <div
                  v-if="accordianActive.includes(item.label)"
                  class="tw-flex tw-flex-col tw-transform tw-transition-transform tw-text-sg-sccc1-w60 tw-pt-4 tw-space-y-4"
                >
                  <div
                    v-for="(subItem, subItem_index) of item.accordian"
                    :key="subItem.label + subItem_index"
                    class="hover:tw-text-sg-sccc1 tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3"
                  >
                    <a
                      :href="$langRedirect(subItem.link, subItem?.isExternal ? 'external': '')"
                      :target="subItem.isExternal ? '_blank' : '_self'"
                    > {{ subItem.label }} </a>
                  </div>
                </div>
              </Transition>
            </div>
          </div>

          <!-- SCCC Menu -->
          <div class="tw-flex tw-flex-col tw-w-1/2 tw-gap-y-4 tw-pt-2 tablet:tw-pt-0">
            <div
              v-for="(item, index) in scccMenu"
              :key="item.label + index"
              class="tw-flex tw-flex-col tw-align-middle hover:tw-text-sg-sccc1 tw-group"
            >
              <div
                v-if="item.isAccordian === false"
                class="tw-inline-flex tw-flex-row"
              >
                <a
                  class="tw-inline-flex tw-flex-row tw-text-sg-sh2-mobile tw-min-w-[250px]
                  tablet:tw-min-w-0 tablet:tw-w-[140px] tablet:tw-text-sg-sh2-tablet
                  xl:tw-w-full desktop:tw-text-sg-sh2"
                  :href="$langRedirect(item.link, item.isExternal ? 'external': '')"
                  :target="item.isExternal ? '_blank' : '_self'"
                >{{ item.label }}
                  <inline-svg
                    v-if="item.isExternal === true"
                    :src="$cdn('icons/top_right_arrow_icon.svg')"
                    class="tw-fill-sg-sccc1-w60 tw-h-4 tw-min-w-4 tw-cursor-pointer group-hover:tw-fill-sg-sccc1 tw-ml-2"
                    alt="Top Arrow Right"
                    :class="{ '-tw-rotate-90 tw-transition-transform': accordianActive.includes(item.label) && getScreenSize() !== 'desktop' }"
                  />
                </a>
              </div>

              <div
                v-else
                class="tw-flex tw-flex-row"
                :class="{
                  'tw-underline tw-text-sg-sh2-mobile tablet:tw-text-sg-sh2-tablet desktop:tw-text-sg-sh2': accordianActive.includes(item.label),
                }"
                @click="handleMenuClick(`${item.label}`)"
              >
                {{ item.label }}
                <div
                  v-if="item.isAccordian === true"
                  class="tw-ml-2 tw-align-middle tw-items-center tw-flex"
                >
                  <inline-svg
                    :src="$cdn('icons/arrow_right_purple.svg')"
                    alt="Arrow Right"
                    :class="{ '-tw-rotate-90 tw-transition-transform': accordianActive.includes(item.label) }"
                    class="tw-fill-sg-sccc1-w60 tw-h-4 tw-w-4 hover:tw-fill-sg-sccc1 group-hover:tw-fill-sg-sccc1"
                  />
                </div>
              </div>
              <Transition name="accordian-fade">
                <div
                  v-if="accordianActive.includes(item.label)"
                  class="tw-flex tw-flex-col tw-transform tw-transition-transform tw-text-sg-sccc1-w60 tw-pt-4 tw-space-y-4"
                >
                  <div
                    v-for="(subItem, subItem_index) of item.accordian"
                    :key="subItem.label + subItem_index"
                    class="hover:tw-text-sg-sccc1"
                  >
                    <a
                      class="tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3"
                      :href="$langRedirect(subItem.link, subItem.isExternal ? 'external': '')"
                      :target="subItem.isExternal ? '_blank' : '_self'"
                    > {{ subItem.label }} </a>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>
        <!-- SCCC Menu -->
      </div>
    </div>

    <!-- Footer footer "watermark" -->
    <div class="tw-grid tw-grid-cols-12 tw-mt-14">
      <div class="tw-col-span-6 tw-flex tw-flex-row tw-gap-x-2">
        <img
          class="tw-w-12 tw-h-12"
          :src="$cdn('icons/footer_PUB.svg')"
          alt="Footer PUB"
        >
        <img
          class="tw-w-12 tw-h-12"
          :src="$cdn('icons/footer_BCA.svg')"
          alt="Footer BCA"
        >
        <img
          class="tw-w-12 tw-h-12"
          :src="$cdn('icons/footer_BizSAFE.svg')"
          alt="Footer BizSafe"
        >
      </div>
      <div
        class="tw-col-span-6 tw-flex tw-justify-end tw-text-sg-sh3-mobile tablet:tw-text-sg-sh3-tablet desktop:tw-text-sg-sh3 tw-text-ap-nobel tw-items-baseline"
      >
        <p
          class="tw-mt-10 tw-uppercase tw-font-thin tw-text-sg-caption tw-text-[#A3A3A3]"
          :class="checkLang() == 'zh-hans'? 'tw-font-notosans' :'tw-font-akkurat'"
        >
          {{ $t('all_rights_reserved') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { getScreenSize } from '../../support/helper';
import { useI18n } from 'vue-i18n';
import { onBeforeUnmount } from 'vue';
import { checkLang } from '../../support/helper';
// import axios from 'axios';

// const emit = defineEmits(['triggerSnackbar']);

const accordianActive = ref<Array<string>>([]);
const emailNewsletter = ref('');
const snackBar = ref();
const acceptTerms = ref(false);
const checkBoxStyle = ref('tw-border-sg-sccc1');
const checkBoxLabelStyle = ref('tw-text-sg-sccc1');

const { t, locale } = useI18n();
function isPageBottomReached() {
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;
  const scrollPosition = window.scrollY || window.pageYOffset || document.body.scrollTop + (document.documentElement && document.documentElement.scrollTop || 0);
  return windowHeight + scrollPosition >= documentHeight;
}

function footerOverscroll(){
  const bg = document.querySelector('.inner') as HTMLDivElement;

  if(bg)
    if(isPageBottomReached()){
      document.body.style.overscrollBehavior = 'none';
      bg.style.visibility = 'hidden';

    }else{
      document.body.style.overscrollBehavior = '';
      bg.style.visibility = 'visible';
    }
}
onMounted(() => {
  // console.log('ap footer mounted');
  window.addEventListener('scroll', footerOverscroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', footerOverscroll);
});

const handleMenuClick = (content: string) => {
  const isInActive = accordianActive.value.find((x) => x === content);
  if (isInActive) {
    // Remove from accordian active
    // const indexToRemove = accordianActive.value.indexOf(content);
    // if (indexToRemove !== -1) {
    //   accordianActive.value.splice(indexToRemove, 1);
    // }
    accordianActive.value = [];
  } else {
    // Add into accordian active
    // accordianActive.value.push(content);
    accordianActive.value = [content];
  }
};


const singaporenMenu = [
  {
    label: t('experience'),
    link: '',
    isAccordian: true,
    isExternal: false,
    accordian: [
      {
        label: t('on_site_exhibition'),
        link: '/exhibition/about',
        isAccordian: false,
        isExternal: false,
      },
      {
        label: t('online_exhibition'),
        link: checkLang() !== 'zh-hans' ? 'https://singaporen.singaporeccc.org.sg/' : 'https://singaporen.singaporeccc.org.sg/homepage?lang=cn',
        isAccordian: false,
        isExternal: true,
      },
      {
        label: t('cultural_extravaganza'),
        link: '/experience/ce/about',
        isAccordian: false,
        isExternal: false,
      } ,

      ...window?.__wordpress_data__?.canView?.includes('sidebar.remix') ? [
        {
          label: t('remix'),
          link: '/experience/remix/about',
          isAccordian: false,
          isExternal: false,
        },
      ] : [],
      // {
      //   label: t('remix'),
      //   link: '#',
      //   isAccordian: false,
      //   isExternal: true,
      // },
    ],
  },
  {
    label: t('roots'),
    link: '/roots',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: t('values'),
    link: '/values',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: t('food'),
    link: '/food',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: t('festivals'),
    link: '',
    isAccordian: true,
    accordian: [
      {
        label: t('overview'),
        link: '/festivals',
      },
      {
        label: t('chinese_new_year'),
        link: '/festivals/chinese-new-year',
      },
      {
        label: t('qingming'),
        link: '/festivals/qingming',
      },
      {
        label: t('duanwu'),
        link: '/festivals/duanwu',
      },
      ...window?.__wordpress_data__?.canView?.includes('sidebar.zy') ? [{
        label: t('zhongyuan'),
        link: '/festivals/zhongyuan',
      }] : [],
      ...window?.__wordpress_data__?.canView?.includes('sidebar.ma') ? [{
        label: t('midautumn'),
        link: '/festivals/mid-autumn',
      }] : [],
    ],
  },
  {
    label: t('practices'),
    link: '/practices',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: t('language'),
    link: '/language',
    isAccordian: false,
    isExternal: false,
  },
  {
    label: t('arts'),
    link: '/arts',
    isAccordian: false,
    isExternal: false,
  },
];

const scccMenu = [
  {
    label: t('sccc_website'),
    link: checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/' : 'https://singaporeccc.org.sg/zh-hans/',
    isAccordian: false,
    isExternal: true,
  },
  ...window?.__wordpress_data__?.canView?.includes('sidebar.culturepaedia') ? [
    {
      label: t('culturepaedia'),
      link: checkLang() !== 'zh-hans' ? 'https://culturepaedia.singaporeccc.org.sg/' : 'https://culturepaedia.singaporeccc.org.sg/zh/',
      isAccordian: false,
      isExternal: true,
    },
  ] : [],
  {
    label: t('contact_us'),
    link:  checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/contact/' : 'https://singaporeccc.org.sg/zh-hans/contact/',
    isAccordian: false,
    isExternal: true,
  },
  {
    label: t('visit'),
    link: checkLang() !== 'zh-hans' ? 'https://singaporeccc.org.sg/visit/' : 'https://singaporeccc.org.sg/zh-hans/visit/',
    isAccordian: false,
    isExternal: true,
  },
];

function submitEmail(e: any) {
  // console.log('email', emailNewsletter.value);

  let status = false;

  let barObj = {
    colour: '',
    text: '',
    type: '',
  };

  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailNewsletter.value)) {
    status = true;
  } else {
    status = false;
  }

  if (status && acceptTerms.value) {
    barObj = {
      colour: '#36A93F',
      text: t('success_subscription'),
      type: 'success',
    };
  } else {
    let errorText = '';
    if (!status) {
      errorText = t('required', { at: '@', dotcom: '.com'});
    } else if (!acceptTerms.value) {
      errorText = t('please_accept_tnc');
      checkCheckbox();
    }
    barObj = {
      colour: '#E4100E',
      text: errorText,
      type: 'error',
    };
    e.preventDefault();
  }
  triggerSnackbar(barObj);
}
function triggerSnackbar(obj: any) {
  // console.log('snackBar.value', snackBar.value);
  snackBar.value.getSnackbarStyle(obj);
}

function checkCheckbox() {
  if (acceptTerms.value == false) {
    checkBoxStyle.value = 'tw-border-red-500';
    checkBoxLabelStyle.value = 'tw-text-red-500';
  }
}

const getLanguage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  return langParam ? langParam : 'en';

};
</script>

<style lang="css">
.email-input-container {
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid purple; /* Bottom border only */
  padding-bottom: 5px; /* Padding at the bottom */
}

.email-input-container input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px 0; /* Padding without sides */
}

.email-input-container .email-icon {
  margin-left: 8px;
  /* Add additional styling for your icon here */
}
</style>
