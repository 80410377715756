import APSlider from 'wp-content/themes/ap_vue_base_theme/src/components/APSlider.vue';
import APAwesome from 'wp-content/themes/ap_vue_base_theme/src/components/APAwesome.vue';
import APLottieViewer from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APLottieViewer.vue';
import APTopNavBar from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APTopNavBar.vue';
import APTextra from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APTextra.vue';
import APSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/shared/ApSwiper.vue';
import APMainLayout from 'wp-content/themes/ap_vue_base_theme/src/components/home/ApMainLayout.vue';
import APFooter from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APFooter.vue';
import APDrawer from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APDrawer.vue';
import APTabs from 'wp-content/themes/ap_vue_base_theme/src/components/APTabs.vue';
import APSectionLayout from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APSectionLayout.vue';
import APSectionLayout2 from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APSectionLayout2.vue';
import RootsReadMoreSection from 'wp-content/themes/ap_vue_base_theme/src/components/roots/RootsReadMoreSection.vue';
import APHighlights from 'wp-content/themes/ap_vue_base_theme/src/components/home/APHighlights.vue';
import APPhotoGrid from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APPhotoGrid.vue';
import APLightbox from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APLightbox.vue';
import APVideoPlayer from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APVideoPlayer.vue';
import APNumberInACircle from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APNumberInACircle.vue';
import APInstructionLayout from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APInstructionLayout.vue';
import APInstructionLayoutWithButton from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APInstructionLayoutWithButton.vue';
import APAwardLayout from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APAwardLayout.vue';
import APExhibitionTextLayout from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APExhibitionTextLayout.vue';
import APScccClickable from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APScccClickable.vue';
import APSnackbar from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APSnackbar.vue';
import APVideos from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APVideos.vue';
import APArticleTemplate from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APArticleTemplate.vue';
import APExhibitionSecondNav from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APExhibitionSecondNav.vue';
import APImageSources from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APImageSources.vue';
import SGButton from 'wp-content/themes/ap_vue_base_theme/src/components/shared/SGButton.vue';
import EventCard from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/EventCard.vue';
import APExhibitionNoticeModal from 'wp-content/themes/ap_vue_base_theme/src/components/exhibitions/APExhibitionNoticeModal.vue';

// CNY
import CNY24Calendar from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNY24Calendar.vue';
import PrimaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/cny/PrimaryNavigation.vue';
import SecondaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/cny/SecondaryNavigation.vue';
import CNY24SectionLayout from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNY24SectionLayout.vue';
import CNYSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNYSwiper.vue';
import CNYOriginStorySection from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNYOriginStorySection.vue';
import CNYOriginCardSection from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNYOriginCardSection.vue';
import CNY24YushengSectionText from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNY24YushengSectionText.vue';
import CNY24SnackGuidesSection from 'wp-content/themes/ap_vue_base_theme/src/components/cny/snack-guides/CNY24SnackGuidesSection.vue';
import FamilyFunProgrammeModal from 'wp-content/themes/ap_vue_base_theme/src/components/cny/family-fun/FamilyFunProgrammeModal.vue';
import FamilyFunSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/cny/family-fun/FamilyFunSwiper.vue';
import CNYHighlights from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNYHighlights.vue';
import CNYStickerSection from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNYStickerSection.vue';
import CnyWhatsOpenModal from 'wp-content/themes/ap_vue_base_theme/src/components/cny/whats-open/CnyWhatsOpenModal.vue';
import APWysiwygViewer from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APWysiwygViewer.vue';
import APFestivalsContainer from 'wp-content/themes/ap_vue_base_theme/src/components/shared/APFestivalsContainer.vue';
import FamilyFunProgrammeScheduleModal from 'wp-content/themes/ap_vue_base_theme/src/components/cny/family-fun/FamilyFunProgrammeScheduleModal.vue';
import GreetThemRightFamilyTreeModal from 'wp-content/themes/ap_vue_base_theme/src/components/cny/greet-them-right/GreetThemRightFamilyTreeModal.vue';
import GreetThemRightQuizModal from 'wp-content/themes/ap_vue_base_theme/src/components/cny/greet-them-right/GreetThemRightQuizModal.vue';
import CNY24GreetingsSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/cny/greetings/CNY24GreetingsSwiper.vue';
import CNY24GreetingsDialect from 'wp-content/themes/ap_vue_base_theme/src/components/cny/greetings/CNY24GreetingsDialect.vue';
import CNYPersonalityQuizSnack from 'wp-content/themes/ap_vue_base_theme/src/components/cny/personality-quiz/CNYPersonalityQuizSnack.vue';
import CNYPersonalityQuizResultModal from 'wp-content/themes/ap_vue_base_theme/src/components/cny/personality-quiz/CNYPersonalityQuizResultModal.vue';
import CNYConversationWindow from 'wp-content/themes/ap_vue_base_theme/src/components/cny/conversations-starters/conversationWindow.vue';
import CNY24UGCSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/cny/CNY24UGCSwiper.vue';

// QINGMING
import QmPrimaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/qm/QmPrimaryNavigation.vue';
import QmSecondaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/qm/QmSecondaryNavigation.vue';
import QmObservationCard from 'wp-content/themes/ap_vue_base_theme/src/components/qm/QmObservationCard.vue';
import QmHomeSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/qm/QmHomeSwiper.vue';
import QmBurningJossCard from 'wp-content/themes/ap_vue_base_theme/src/components/qm/QmBurningJossCard.vue';
import QmPreservingQingMing from 'wp-content/themes/ap_vue_base_theme/src/components/qm/QmPreservingQingMing.vue';

// Experience-CE
import CETextLayout from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CETextLayout.vue';
import CESwiper from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CESwiper.vue';
import CENavigationBar from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CENavigationBar.vue';
import CEPartnerSection from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CEPartnerSection.vue';
import CESocialBtn from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CESocialBtn.vue';
import CEVideoCard from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CEVideoCard.vue';
import CEImageModal from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CEImageModal.vue';
import CeWysiwygViewer from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CeWysiwygViewer.vue';
import CEVideos from 'wp-content/themes/ap_vue_base_theme/src/components/experience/ce/CEVideos.vue';

//Experience-REMIX
import RemixClickable from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/RemixClickable.vue';
import ValuesJangandFoxSection from 'wp-content/themes/ap_vue_base_theme/src/components/values/ValuesJangandFoxSection.vue';
import ValuesJangandFoxSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/values/ValuesJangandFoxSwiper.vue';
import ValuesJangandFoxImageModal from 'wp-content/themes/ap_vue_base_theme/src/components/values/ValuesJangandFoxImageModal.vue';

//DUANWU
import DwHomeSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/dw/DwHomeSwiper.vue';
import DwPrimaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/dw/DwPrimaryNavigation.vue';
import DwSecondaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/dw/DwSecondaryNavigation.vue';
import DwUniqueDumplingsSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/dw/DwUniqueDumplingsSwiper.vue';
import DwWhatsOpenModal from 'wp-content/themes/ap_vue_base_theme/src/components/dw/DwWhatsOpenModal.vue';
import DwPersonalityQuizDumpling from 'wp-content/themes/ap_vue_base_theme/src/components/dw/DwPersonalityQuizDumpling.vue';
import DwPersonalityQuizResultModal from 'wp-content/themes/ap_vue_base_theme/src/components/dw/DwPersonalityQuizResultModal.vue';

//ZHONGYUAN
import ZyActivitiesSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyActivitiesSwiper.vue';
import ZyCardSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyCardSwiper.vue';
import ZyHomeSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyHomeSwiper.vue';
import ZyPaperOfferingSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyPaperOfferingSwiper.vue';
import ZyTaboosSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyTaboosSwiper.vue';
import ZyPrimaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyPrimaryNavigation.vue';
import ZySecondaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZySecondaryNavigation.vue';
import ZyPaperTear from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyPaperTear.vue';
import ZyImageCard from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyImageCard.vue';
import ZyFireSafetyModal from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyFireSafetyModal.vue';
import ZyAuctionSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyAuctionSwiper.vue';
import ZyGetaiSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/zy/ZyGetaiSwiper.vue';

// MID-AUTUMN
import MaSecondaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaSecondaryNavigation.vue';
import MaPrimaryNavigation from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaPrimaryNavigation.vue';
import MaHomeSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaHomeSwiper.vue';
import MaFamilyFunProgrammeModal from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaFamilyFunProgrammeModal.vue';
import MaFamilyFunProgrammeCard from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaFamilyFunProgrammeCard.vue';
import MaAboutCelebrationSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaAboutCelebrationSwiper.vue';
import MaPolaroidCard from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaPolaroidCard.vue';
import MaAboutTypicalMooncakeSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaAboutTypicalMooncakeSwiper.vue';
import MaBottomCTASection from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaBottomCTASection.vue';
import MaWaveBreakLine from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaWaveBreakLine.vue';
import Ma101CelebrationSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/ma/Ma101CelebrationSwiper.vue';
import MaLanternCard from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaLanternCard.vue';
import MaLanternRiddleSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaLanternRiddleSwiper.vue';
import MaFlipCard from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaFlipCard.vue';
import MaUniqueMooncakeFlavourSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaUniqueMooncakeFlavourSwiper.vue';
import MaProgramCard from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaProgramCard.vue';
import MaWhatsOpenModal from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaWhatsOpenModal.vue';
import MaPersonalityQuiz from 'wp-content/themes/ap_vue_base_theme/src/components/ma/personality-quiz/MaPersonalityQuiz.vue';
import MaPersonalityQuizResultModal from 'wp-content/themes/ap_vue_base_theme/src/components/ma/personality-quiz/MaPersonalityQuizResultModal.vue';

import MaPartnerSection from 'wp-content/themes/ap_vue_base_theme/src/components/ma/MaPartnerSection.vue';

// REMIX
import REMIXEventCard from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXEventCard.vue';
import REMIXTextLayout from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXTextLayout.vue';
import REMIXSwiper from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXSwiper.vue';
import REMIXNavigationBar from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXNavigationBar.vue';
import REMIXPartnerSection from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXPartnerSection.vue';
import REMIXSocialBtn from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXSocialBtn.vue';
import REMIXVideoCard from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXVideoCard.vue';
import REMIXImageModal from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXImageModal.vue';
import REMIXWysiwygViewer from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXWysiwygViewer.vue';
import REMIXVideos from 'wp-content/themes/ap_vue_base_theme/src/components/experience/remix/REMIXVideos.vue';

export default {
  'ap-slider': APSlider,
  'ap-awesome': APAwesome,
  'ap-lottie-viewer': APLottieViewer,
  'ap-top-nav-bar': APTopNavBar,
  'ap-textra': APTextra,
  'ap-swiper': APSwiper,
  'ap-main-layout': APMainLayout,
  'ap-footer': APFooter,
  'ap-drawer': APDrawer,
  'ap-tabs': APTabs,
  'ap-section-layout': APSectionLayout,
  'ap-section-layout-2': APSectionLayout2,
  'ap-highlights': APHighlights,
  'roots-read-more-section': RootsReadMoreSection,
  'ap-photo-grid': APPhotoGrid,
  'ap-light-box': APLightbox,
  'ap-video-player': APVideoPlayer,
  'ap-number-in-a-circle': APNumberInACircle,
  'ap-instruction-layout': APInstructionLayout,
  'ap-instruction-layout-with-button': APInstructionLayoutWithButton,
  'ap-award-layout': APAwardLayout,
  'ap-exhibition-text-layout': APExhibitionTextLayout,
  'ap-sccc-clickable': APScccClickable,
  'ap-snackbar': APSnackbar,
  'ap-videos': APVideos,
  'ap-article-template': APArticleTemplate,
  'ap-wysiwyg-viewer': APWysiwygViewer,
  'ap-festivals-container': APFestivalsContainer,
  'ap-exhibition-second-nav': APExhibitionSecondNav,
  'ap-image-sources': APImageSources,
  'sg-button': SGButton,
  'sg-event-card': EventCard,
  'ap-exhibition-notice-modal': APExhibitionNoticeModal,

  //values
  'values-jangandfox-section': ValuesJangandFoxSection,
  'values-jangandfox-swiper': ValuesJangandFoxSwiper,
  'values-jangandfox-image-modal':ValuesJangandFoxImageModal,

  // CNY
  'cny-24-calendar': CNY24Calendar,
  'primary-navigation': PrimaryNavigation,
  'secondary-navigation': SecondaryNavigation,
  'cny-24-section-layout': CNY24SectionLayout,
  'cny-swiper': CNYSwiper,
  'cny-origin-story-section': CNYOriginStorySection,
  'cny-origin-card-section': CNYOriginCardSection,
  'cny-24-yusheng-section-text': CNY24YushengSectionText,
  'family-fun-programme-modal':FamilyFunProgrammeModal,
  'family-fun-programme-schedule-modal':FamilyFunProgrammeScheduleModal,
  'family-fun-swiper':FamilyFunSwiper,
  'cny-highlights': CNYHighlights,
  'cny-sticker-section': CNYStickerSection,
  'cny-whats-open-modal':CnyWhatsOpenModal,
  'cny-greet-them-right-family-tree-modal':GreetThemRightFamilyTreeModal,
  'cny-greet-them-right-quiz-modal':GreetThemRightQuizModal,
  'cny-personality-quiz-snack':CNYPersonalityQuizSnack,
  'cny-personality-quiz-result-modal':CNYPersonalityQuizResultModal,
  'cny-24-greetings-swiper': CNY24GreetingsSwiper,
  'cny-24-greetings-dialect': CNY24GreetingsDialect,
  'cny-24-snack-guides-section': CNY24SnackGuidesSection,
  'cny-conversation-window': CNYConversationWindow,
  'cny-24-ugc-swiper': CNY24UGCSwiper,

  //Qing Ming
  'qm-primary-navigation': QmPrimaryNavigation,
  'qm-secondary-navigation': QmSecondaryNavigation,
  'qm-observation-card': QmObservationCard,
  'qm-burning-joss-card': QmBurningJossCard,
  'qm-preserving-qing-ming': QmPreservingQingMing,
  'qm-home-swiper': QmHomeSwiper,

  //CE
  'ce-text-layout': CETextLayout,
  'ce-swiper': CESwiper,
  'ce-navigation-bar': CENavigationBar,
  'ce-partner-section':CEPartnerSection,
  'ce-social-btn':CESocialBtn,
  'ce-video-card':CEVideoCard,
  'ce-image-modal':CEImageModal,
  'ce-wysiwyg-viewer':CeWysiwygViewer,
  'ce-videos':CEVideos,

  //REMIX
  'remix-clickable':RemixClickable,

  //Duanwu
  'dw-home-swiper': DwHomeSwiper,
  'dw-primary-navigation': DwPrimaryNavigation,
  'dw-secondary-navigation': DwSecondaryNavigation,
  'dw-unique-dumplings-swiper':DwUniqueDumplingsSwiper,
  'dw-whats-open-modal':DwWhatsOpenModal,
  'dw-personality-quiz-dumpling':DwPersonalityQuizDumpling,
  'dw-personality-quiz-result-modal':DwPersonalityQuizResultModal,

  //Zhongyuan
  'zy-activities-swiper': ZyActivitiesSwiper,
  'zy-card-swiper': ZyCardSwiper,
  'zy-home-swiper': ZyHomeSwiper,
  'zy-paper-offering-swiper': ZyPaperOfferingSwiper,
  'zy-taboos-swiper':ZyTaboosSwiper,
  'zy-primary-navigation': ZyPrimaryNavigation,
  'zy-secondary-navigation': ZySecondaryNavigation,
  'zy-paper-tear':ZyPaperTear,
  'zy-image-card':ZyImageCard,
  'zy-fire-safety-modal':ZyFireSafetyModal,
  'zy-auction-swiper':ZyAuctionSwiper,
  'zy-getai-swiper':ZyGetaiSwiper,

  // Mid Autumn
  'ma-primary-navigation': MaPrimaryNavigation,
  'ma-secondary-navigation': MaSecondaryNavigation,
  'ma-home-swiper':MaHomeSwiper,
  'ma-family-fun-programme-modal':MaFamilyFunProgrammeModal,
  'ma-family-fun-programme-card':MaFamilyFunProgrammeCard,
  'ma-about-celebration-swiper':MaAboutCelebrationSwiper,
  'ma-polaroid-card':MaPolaroidCard,
  'ma-about-typical-mooncake-swiper':MaAboutTypicalMooncakeSwiper,
  'ma-bottom-cta-section':MaBottomCTASection,
  'ma-wave-break-line':MaWaveBreakLine,
  'ma-101-celebration-swiper':Ma101CelebrationSwiper,
  'ma-lantern-card':MaLanternCard,
  'ma-lantern-riddle-swiper':MaLanternRiddleSwiper,
  'ma-flip-card':MaFlipCard,
  'ma-unique-mooncake-flavour-swiper':MaUniqueMooncakeFlavourSwiper,
  'ma-program-card':MaProgramCard,
  'ma-whats-open-modal':MaWhatsOpenModal,
  'ma-personality-quiz':MaPersonalityQuiz,
  'ma-personality-quiz-result-modal':MaPersonalityQuizResultModal,
  'ma-partner-section':MaPartnerSection,


  // REMIX
  'remix-event-card':REMIXEventCard,
  'remix-text-layout':REMIXTextLayout,
  'remix-swiper':REMIXSwiper,
  'remix-navigation-bar':REMIXNavigationBar,
  'remix-partner-section':REMIXPartnerSection,
  'remix-social-btn':REMIXSocialBtn,
  'remix-video-card':REMIXVideoCard,
  'remix-image-modal':REMIXImageModal,
  'remix-wysiwyg-viewer':REMIXWysiwygViewer,
  'remix-videos':REMIXVideos
};
